import React from "react"
import { Authenticated2 } from "../../components/auth/Authenticated2"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { TypingTest2 } from "../../components/typing-test-2/TypingTest2"

export default function TypingTest2Page() {
  return (
    <DebugLayout title="Typing Test 2">
      <Authenticated2>
        <TypingTest2 />
      </Authenticated2>
    </DebugLayout>
  )
}
